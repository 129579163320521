/*.searchBox .ant-input-search-button {*/
/*    border-start-end-radius: 30px !important;*/
/*    border-end-end-radius: 30px !important;*/
/*    color: #e70014 !important;*/
/*}*/

/*.searchBox .ant-input-affix-wrapper:not(:last-child) {*/
/*    border-start-start-radius: 30px !important;*/
/*    border-end-start-radius: 30px !important;*/
/*    border-right: 0 !important;*/
/*}*/

/*.searchBox .ant-input-affix-wrapper {*/
/*    border-right: 0 !important;*/
/*}*/

/*.searchBox .ant-btn {*/
/*    border-left: 0 !important;*/
/*}*/

/*.searchBox:hover .ant-input-affix-wrapper,*/
/*.searchBox:focus-within .ant-input-affix-wrapper,*/
/*.searchBox:hover .ant-btn,*/
/*.searchBox:focus-within .ant-btn {*/
/*    box-shadow: 0 0 10px 0 rgba(231, 0, 20, 0.2) !important;*/
/*    border-top: 1px solid #f8eaec !important;*/
/*    border-right: 1px solid #f8eaec !important;*/
/*    border-bottom: 1px solid #f8eaec !important;*/
/*    border-left: 0 !important;*/
/*}*/
.inputSearchBox {
    border-color: #f8eaec;
    width: 540px!important;
    border-radius: 30px
}

.inputSearchBox:hover {
    box-shadow: 0 0 10px 0 rgba(231, 0, 20, 0.2);
}

/*#cartNumber {*/
/*    position: relative;*/
/*    top: -11px;*/
/*    left: -4px;*/
/*    line-height: 13px;*/
/*    border-radius: 13px;*/
/*    font-size: 9px;*/
/*    color: #fff;*/
/*    padding: 0 5px;*/
/*    background: #e70216;*/
/*}*/

#navbar .ant-menu-item-selected {
    color: #eb0014 !important;
}

#navbar .ant-menu-item-active::after,
#navbar .ant-menu-item-selected::after {
    border-bottom-color: rgba(235, 0, 20, 0.25) !important;
}

#navPath a,
#navPath li {
    color: white;
}
