.rootCategoryNode {
    font-weight: bold;
    font-size: 16px;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light .ant-menu-item-selected {
    color: #e4080e !important;
}

.categoryCard {
    width: 272px;
    height: 289px;
    display: flex;
    justify-content: center;
}

.categoryCard img {
    height: 216px;
    width: 250px;
    object-fit: contain;
}

.categoryCard .title {
    text-align: center;
    font-weight: bold;
    color: #333333;
}

.productCard {
    width: 272px;
    height: 380px;
    display: flex;
    justify-content: center;
}

.productCard img {
    height: 250px;
    width: 270px;
    object-fit: contain;
}

.productCard .title {
    margin-top: 6px;
    text-align: center;
    color: #444444;
}

.productCard p {
    text-align: center;
    margin-top: 3px;
    font-size: 18px;
    font-weight: bold;
    color: #e4060c;
}

